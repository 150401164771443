/* @media only screen and (max-width: 992px) {
  .inventoryElementImage {
    border-radius: 15px 15px 0px 0px !important;
  }
  .inventoryElementDescriptionCol {
    border-left: none !important;
  }
  .inventoryElementQuantityCol {
    border-left: none !important;
    text-align: center !important;
  }
  .inventoryElementButtonCol {
    padding: 25px !important;
  }
}

.inventoryElementCard {
  width: 100%;
  min-height: 200px;
  margin: 15px 0px 30px 0;
  border-radius: 15px;
  background-color: #fff;
}

.inventoryElementImageCol {
  min-height: 200px;
}

.inventoryElementTitleCol {
  padding: 10px !important;
}

.inventoryElementDescriptionCol {
  padding: 10px !important;
  border-left: solid 1px #f8cc0e;
}

.inventoryElementQuantityCol {
  border-left: solid 1px #f8cc0e;
  text-align: right;
}

.inventoryElementImage {
  min-width: 100%;
  border-radius: 15px 0px 0px 15px;
} */

@media only screen and (max-width: 992px) {
  .inventoryElementImageCol {
    border-radius: 15px 15px 0px 0px !important;
  }
  .inventoryElementDescriptionCol {
    border-left: none !important;
  }
  .inventoryElementQuantityCol {
    border-left: none !important;
    text-align: center !important;
  }
  .inventoryElementButtonCol {
    padding: 25px !important;
  }
}

.inventoryElementCard {
  width: 100%;
  min-height: 200px;
  margin: 15px 0px 30px 0;
  border-radius: 15px;
  background-color: #fff;
}

.inventoryElementImageCol {
  position: relative;
  min-height: 200px;
  border-radius: 15px 0px 0px 15px;
  /* max-width: 275px; */
}

.inventoryElementTitleCol {
  padding: 10px !important;
}

.inventoryElementDescriptionCol {
  padding: 10px !important;
  border-left: solid 1px #f8cc0e;
}

.inventoryElementQuantityCol {
  border-left: solid 1px #f8cc0e;
  /* text-align: right; */
}
.inventoryElementQuantity {
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
}

.inventoryElementImage {
  min-width: 100%;
  max-width: 275px;
  height: 275px;
  border-radius: 15px 0px 0px 15px;
}

.inventoryCardIcons {
  position: absolute;
  transition: 0.4s;
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 15px 0px 0px 15px;
  align-items: center;
  justify-content: center;
}

.inventoryElementImageCol:hover {
  cursor: pointer;
}

.inventoryElementImageCol:hover .inventoryCardIcons {
  background-color: rgb(34, 34, 34, 0.8);
  cursor: pointer;
}

.inventoryElementImageCol:hover .inventoryCardIcons div {
  display: flex !important;
}
