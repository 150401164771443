.keepmepointsCard {
  position: relative;
  width: 100%;
  margin: 0px 15px 15px 15px;
  /* min-height: 300px; */
  max-width: 300px;
  border-radius: 10px;
  background-color: #fff;
  transition: 0.4s;
}

.keepmepointsCardTitle {
  position: relative;
  min-width: 300px;
  min-height: 300px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}

.keepmepointsCardIcons {
  position: absolute;
  transition: 0.4s;
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  justify-content: center;
}

.keepmepointsCardTitle:hover {
  cursor: pointer;
}

.keepmepointsCardTitle:hover .keepmepointsCardIcons {
  background-color: rgb(34, 34, 34, 0.8);
  cursor: pointer;
}

.keepmepointsCardTitle:hover .keepmepointsCardIcons div {
  display: flex !important;
}

.keepmepointsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 990px;
}

.keepmepointsCardImage {
  width: 350px;
  height: 300px;
  border-radius: 10px 10px 0px 0px;
  /* background-color: #f4f4f4; */
  display: flex;
  transition: 0.4s;
}
