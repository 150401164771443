@media only screen and (max-width: 990px) {
  .outputsList {
    max-width: 661px !important;
  }
}

@media only screen and (max-width: 661px) {
  .outputsList {
    max-width: 330px !important;
  }
}
.headerToggle:hover {
  cursor: pointer;
}

.outputCard {
  position: relative;
  /* width: 100%; */
  margin: 0px 15px 15px 15px;
  width: 300px;
  border-radius: 10px;
  background-color: #fff;
  transition: 0.4s;

  /* position: relative;
  width: 100%;
  margin: 0px auto;
  min-height: 300px;
  max-width: 300px;
  border-radius: 10px;
  background-color: #fff;
  transition: 0.4s; */

  /* border-width: 1px;
  border-style: solid;
  border-color: black;
  width: 200px;
  height: 300px; */
}

.outputCardTitle {
  position: relative;
  background-color: #ffd700;
  min-height: 200px;
  border-radius: 10px 10px 0px 0px;
  padding: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}

.outputCardIcons {
  position: absolute;
  transition: 0.4s;
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  justify-content: center;
}

.outputCardIcon {
  color: #fff;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 50px;
}

.outputCardTitle:hover {
  cursor: pointer;
}

.outputCardTitle:hover .outputCardIcons {
  background-color: rgb(34, 34, 34, 0.8);
  cursor: pointer;
}

.outputCardTitle:hover .outputCardIcons div {
  display: flex !important;
}

.outputDetailsCardTable .ant-collapse-header {
  background-color: #ccc;
}

.outputsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 990px;
}

.outputDrawer .ant-drawer-wrapper-body {
  overflow-y: scroll;
  height: 100%;
}

.outputDrawer .ant-drawer-wrapper-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  background-color: #f5f5f5;
}

.outputDrawer .ant-drawer-wrapper-body::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #f5f5f5;
  border-radius: 15px;
}

.outputDrawer .ant-drawer-wrapper-body::-webkit-scrollbar-thumb {
  background-color: rgb(255, 215, 0, 0.7);
}
