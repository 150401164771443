.storeCard {
  padding: 3vh;
  border-radius: 10px;
  text-align: center;
  transition: 0.4s;
}

.storeCard:hover {
  background-color: white;
}

.storeCard:hover .underlineStoreCard {
  background-color: #ffd700 !important;
}
