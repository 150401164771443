/* .loginForm {
  margin-top: 20px;
} */

.loginFormContainer {
  text-align: center;
  margin: auto;
  /* transform: translateY(20%); */
}

.loginEvents {
  text-align: left;
  min-height: 30px;
  margin-bottom: 20px;
  width: 100%;
}

.loginEvents small {
  color: #fff;
}

.loginEventsContainer {
  display: inline-block;
  background-color: #fafafa;
  font-size: 12;
  border: solid 1px #d9d9d9;
  padding: 2px;
  border-radius: 5px;
  opacity: 1;
  margin-right: 5px;
}
.login {
  background-image: url("../../assets/images/keepme-login-background1.jpg"),
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-blend-mode: overlay;
  background-size: cover;
  /* height: 100vh; */
}

.resetPasswordPageContainer {
  display: flex;
  height: 100vh;
}

.resetPasswordPageFormCard {
  margin: auto;
  padding: 30px;
  border-radius: 15px;
  background-color: #fff;
}
