/*Reports*/

.circleMetric {
  transition: 0.2s;
  margin: 5px;
  display: inline-block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  font-weight: bold;
  color: #222;
  text-align: center;
  background: #ffd700;
  border: 5px solid #fff;
}

.metric {
  width: 90px;
  height: 90px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.metricCardCol {
  height: 100%;
  margin: 15px 0px;
}

.plusMetricCard {
  background-color: #ffd700;
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  height: 100%;
}

.doughnutCardContainer {
  background-color: #fff;
  border-radius: 15px;
  padding: 30px;
  height: 100%;
}
