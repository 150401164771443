.eventTrackerButton {
  position: fixed;
  bottom: 25px;
  right: 20px;
}
.eventDrawer .ant-drawer-wrapper-body {
  overflow: hidden !important;
}

.eventDrawer .ant-drawer-close {
  color: white;
}

@media only screen and (max-width: 600px) {
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.eventCard {
  background-color: #404242;
}
