/* html {
  margin: 0;
  padding: 0;
  min-height: 100%;
  width: 100%;
} */
body {
  /* margin: 0;
  padding: 0;
  min-height: 100%;
  width: 100%; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f2f1;
}
h1,
h2,
h3,
h4,
a,
li,
p,
small,
div {
  font-family: "Kiro";
}
html::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  background-color: #f5f5f5;
}

html::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #f5f5f5;
  border-radius: 15px;
}

html::-webkit-scrollbar-thumb {
  background-color: rgb(255, 215, 0, 0.7);
}

.keepmeScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  background-color: #f5f5f5;
}

.keepmeScroll::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #f5f5f5;
  border-radius: 15px;
}

.keepmeScroll::-webkit-scrollbar-thumb {
  background-color: rgb(255, 215, 0, 0.7);
}

@font-face {
  font-family: "Kiro";
  src: local("Kiro"),
    url(./assets/fonts/kiro/Kiro-Regular_0.otf) format("opentype");
}

@font-face {
  font-family: "Kiro";
  src: local("Kiro"), url(./assets/fonts/kiro/Kiro-Bold.otf) format("opentype");
  font-weight: bold;
}

strong {
  font-weight: bold;
}
