@media only screen and (max-width: 992px) {
  .serieElementImageCol {
    border-radius: 15px 15px 0px 0px !important;
  }
  .serieElementDescriptionCol {
    border-left: none !important;
  }
  .serieElementQuantityCol {
    border-left: none !important;
    text-align: center !important;
  }
  .serieElementButtonCol {
    padding: 25px !important;
  }
}

.serieElementCard {
  width: 100%;
  min-height: 200px;
  margin: 15px 0px 30px 0;
  border-radius: 15px;
  background-color: #fff;
}

.serieElementImageCol {
  position: relative;
  min-height: 200px;
  border-radius: 15px 0px 0px 15px;
  /* max-width: 275px; */
}

.serieElementTitleCol {
  padding: 10px !important;
}

.serieElementDescriptionCol {
  padding: 10px !important;
  border-left: solid 1px #f8cc0e;
}

.serieElementQuantityCol {
  border-left: solid 1px #f8cc0e;
  /* text-align: right; */
}
.serieElementQuantity {
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
}

.serieElementImage {
  min-width: 100%;
  max-width: 275px;
  height: 275px;
  border-radius: 15px 0px 0px 15px;
}

.serieCardIcons {
  position: absolute;
  transition: 0.4s;
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 15px 0px 0px 15px;
  align-items: center;
  justify-content: center;
}

.serieCardIcons:hover {
  cursor: pointer;
}

.serieElementImageCol:hover .serieCardIcons {
  background-color: rgb(34, 34, 34, 0.8);
  cursor: pointer;
}

.serieElementImageCol:hover .serieCardIcons div {
  display: flex !important;
}
