.ant-carousel .slick-slide {
  display: flex;
  justify-content: center;
  text-align: center;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.cartOutputDetailsCard {
  width: 100%;
  min-height: 100px;
  margin: 15px 0px 20px 0px;
  border-radius: 10px;
  background-color: #fff;
}

.cartAditionalServicesCard {
  width: 100%;
  min-height: 100px;
  margin: 15px 0px;
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;
}

.cartKeepmepointsCard {
  height: 440px;
  margin: 15px 0px 30px 0px;
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;
}

.cartOutputResumeCard {
  width: 100%;
  min-height: 100px;
  margin: 15px 0px 30px 0px;
  border-radius: 10px;
  background-color: #fff;
  padding: 0px 20px 20px 20px;
}
