@media only screen and (max-width: 990px) {
  .complementsList {
    max-width: 661px !important;
  }
}

@media only screen and (max-width: 661px) {
  .complementsList {
    max-width: 330px !important;
  }
}

.complementCard {
  position: relative;
  width: 100%;
  margin: 0px auto 15px auto;
  /* height: 100%; */
  /* min-height: 300px; */
  /* max-width: 300px; */
  border-radius: 10px;
  background-color: #fff;
  transition: 0.4s;
}

.complementCardTitle {
  position: relative;
  /* min-width: 300px; */
  /* min-height: 300px; */
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}

.complementCardIcons {
  position: absolute;
  transition: 0.4s;
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  justify-content: center;
}

.complementCardTitle:hover {
  cursor: pointer;
}

.complementCardTitle:hover .complementCardIcons {
  background-color: rgb(34, 34, 34, 0.8);
  cursor: pointer;
}

.complementCardTitle:hover .complementCardIcons div {
  display: flex !important;
}

.ant-statistic-content span {
  font-family: "Kiro";
  display: inline;
  font-size: 14px;
}

.complementsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 990px;
}

.elementCardImage {
  width: 350px;
  height: 300px;
  border-radius: 10px 10px 0px 0px;
  /* background-color: #f4f4f4; */
  display: flex;
  transition: 0.4s;
}
