@media only screen and (max-width: 992px) {
  .profileCardCol {
    position: static !important;
    margin-top: 0px !important;
    z-index: -1 !important;
  }
  .ghostCol {
    display: none;
  }
}

.profileCard {
  margin: 30px auto;
  width: 70%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
  /* min-height: 170px; */
}
.profileCard h1,
p {
  margin-bottom: 0;
}

.circleColor {
  cursor: pointer;
  margin: 20px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: block;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  line-height: 55px;
  text-align: center;
}

.circle {
  transition: 0.2s;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 22px;
  color: #fff;
  line-height: 55px;
  text-align: center;
  background: #ffd700;
  border: 2px solid #fff;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}

.circle:hover {
  background-color: rgb(34, 34, 34, 0.5);
}

.brandTags .ant-tag {
  margin: 10px;
  font-size: 16px;
}

.profileCardCol {
  position: fixed;
  top: 0px;
  left: 0px;
  margin-top: 64px;
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.brandKitTitle {
  padding: 10px;
  border-bottom: 3px solid #ffd700;
  max-width: 150px;
  text-align: center;
  z-index: -1;
}

.brandColorCard {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  min-height: 285px;
  padding: 15px;
  margin-left: 5px;
}

.brandKitCard {
  /* width: 100%; */
  background-color: #fff;
  border-radius: 10px;
  min-height: 250px;
  padding: 15px;
  /* margin-left: 3px; */
}

.brandLogoTitle {
  padding: 10px;
  text-align: center;
  font-size: 18px;
}
