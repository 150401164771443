/* 
boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)"
Negro: #222222
Amarillo: #ffd700
Gris: #f3f2f1 
*/
/*General components*/
.ant-btn.ant-btn-primary {
  color: #222222;
  border-radius: 0;
}

.ant-table-pagination.ant-pagination {
  margin-right: 10px;
  margin-left: 10px;
}

.pageContainer {
  padding: 30px 0;
}

.pageTitle {
  color: #222222;
  text-align: center;
  margin-bottom: 0;
  font-size: 44px;
}

.underlineMetric {
  border-bottom: 2px solid #ffd700;
}

/* header */
.logo {
  margin: 0 auto;
  float: left;
  width: 100%;
  text-align: center;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  margin-top: -3px;
  transition: 0.2s;
  border-bottom: 3px solid #ffd700;
}

.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #ffd700;
}

.headerContainerRow {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #222222;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1;
}

/*Pagination*/
.ant-pagination-item-active {
  background-color: #ffd700;
  color: #222222;
  border-radius: 30px;
}
.ant-pagination-item-active a {
  color: #222222;
}

/*Animation*/
.fade-enter {
  opacity: 0;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-appear {
  opacity: 0;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-leave {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-enter.fade-enter-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fade-appear.fade-appear-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fade-leave.fade-leave-active {
  animation-name: fadeOut;
  animation-play-state: running;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
